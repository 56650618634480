import moment from "moment"
import ThemisInput from "@/components/shared/input"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisCascadedInput from "@/components/shared/cascaded-input"
import { getBarChartOptions, getHorizontalBarChartHeight } from "@/constants/charts"

export default {
  name      : "Insights",
  components: {
    ThemisInput,
    ThemisCascadedInput,
    ThemisDateTimePicker
  },
  data() {
    return {
      showDateRangeMenu: false,
      selectedDates    : new Array()
    }
  },
  props: {
    pKpis                : Array,
    pDomains             : Array,
    pChannels            : Array,
    pLanguages           : Array,
    pLoggedInUserPolicies: Object
  },
  computed: {
    defaultDateRange() {
      return {
        startDate: moment().subtract(365, "days").format("YYYY-MM-DD"),
        endDate  : moment().format("YYYY-MM-DD")
      }
    },
    dateRangeText() {
      if (!this.selectedDates.length) {
        this.selectedDates = [this.defaultDateRange.startDate, this.defaultDateRange.endDate]
      }
      return this.selectedDates.map(date => moment(date).format("DD-MM-YYYY")).join(" ~ ")
    },
    previousTimeRange() {
      const numberOfDays = moment(this.selectedDates[1]).diff(this.selectedDates[0], "days")
      return [
        moment(this.selectedDates[0]).subtract(numberOfDays + 1, "days"),
        moment(this.selectedDates[0]).subtract(1, "days")
      ]
    },
    casesInCurrentDateRange() {
      return this.filterKpisByDateRange(this.selectedDates)
    },
    casesInPreviousDateRange() {
      return this.filterKpisByDateRange(this.previousTimeRange)
    },
    numberOfCasesInCurrentDateRange() {
      return this.casesInCurrentDateRange.length
    },
    averageTimeToCloseInCurrentDateRange() {
      return this.getAverageTimeToClose(this.casesInCurrentDateRange)
    },
    averageTimeToCloseInPreviousDateRange() {
      return this.getAverageTimeToClose(this.casesInPreviousDateRange)
    },
    differenceInCases() {
      return this.getDifference(
        this.numberOfCasesInCurrentDateRange,
        this.casesInPreviousDateRange.length
      )
    },
    monthsForGraph() {
      const months = []
      if (this.selectedDates.length === 2) {
        const startDate = moment(this.selectedDates[0])
        const endDate   = moment(this.selectedDates[1])

        while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
          months.push(startDate.format("MMM YYYY"))
          startDate.add(1, "month")
        }

        return months
      }
    },
    differenceInAverageTimeToClose() {
      return this.getDifference(
        this.averageTimeToCloseInCurrentDateRange,
        this.averageTimeToCloseInPreviousDateRange,
        this.casesInPreviousDateRange.length
      )
    },
    reportsInCurrentDateRange() {
      return this.getReports(this.casesInCurrentDateRange)
    },
    reportsInPreviousDateRange() {
      return this.getReports(this.casesInPreviousDateRange)
    },
    numberOfReportsInCurrentDateRange() {
      return this.reportsInCurrentDateRange.length
    },
    differenceInReports() {
      return this.getDifference(
        this.numberOfReportsInCurrentDateRange,
        this.reportsInPreviousDateRange.length
      )
    },
    currentNumberOfCheckBackReports() {
      return this.getNumberOfCheckBackReports(this.reportsInCurrentDateRange)
    },
    previousNumberOfCheckBackReports() {
      return this.getNumberOfCheckBackReports(this.reportsInPreviousDateRange)
    },
    currentReportsCheckBackRate() {
      return this.getCheckBackRate(this.numberOfReportsInCurrentDateRange, this.currentNumberOfCheckBackReports)
    },
    previousReportsCheckBackRate() {
      return this.getCheckBackRate(this.reportsInPreviousDateRange.length, this.previousNumberOfCheckBackReports)
    },
    differenceInReporterCheckBack() {
      return this.getDifference(
        this.currentReportsCheckBackRate,
        this.previousReportsCheckBackRate,
        this.reportsInPreviousDateRange.length
      )
    },
    kpisToDisplay() {
      const result = []
      if (this.isSelectPresentInViewPolicies("Issue view")) {
        result.push({
          kpiTitle      : this.$t("1141"),
          value         : this.numberOfCasesInCurrentDateRange,
          difference    : this.differenceInCases,
          class         : this.getColor(this.differenceInCases, true),
          kpiTooltipText: this.$t("1627")
        })
      }
      if (this.isSelectPresentInViewPolicies("Report view")) {
        result.push({
          kpiTitle      : this.$t("474"),
          value         : this.numberOfReportsInCurrentDateRange,
          difference    : this.differenceInReports,
          class         : this.getColor(this.differenceInReports, true),
          kpiTooltipText: this.$t("1640")
        })
      }

      if (this.isSelectPresentInViewPolicies("Message view")) {
        result.push({
          kpiTitle: this.$t("28"),
          value   : this.checkNotApplicable(
            this.currentReportsCheckBackRate, "27", "percentage"
          ),
          difference: this.checkNotApplicable(
            this.differenceInReporterCheckBack, "27", "percentage"
          ),
          class         : this.getColor(this.differenceInReporterCheckBack, true),
          kpiTooltipText: this.$t("29")
        })
      }

      if (this.checkPropertyInIssueViewPoliciesSelect("createdAt") &&
        this.checkPropertyInIssueViewPoliciesSelect("receivedAt")) {
        result.push({
          kpiTitle: this.$t("1144"),
          value   : this.checkNotApplicable(
            this.averageTimeToCloseInCurrentDateRange, "1146", "numberOfDays"
          ),
          difference    : this.differenceInAverageTimeToClose,
          class         : this.getColor(this.differenceInAverageTimeToClose, false),
          kpiTooltipText: this.$t("1145")
        })
      }
      return result
    },
    totalNumberOfCasesGraph() {
      const result     = []
      const monthYears = this.monthYears()
      if (monthYears) {
        const monthYears         = this.monthYears()
        const speakUpCases       = monthYears.map(() => 0)
        const manuallyAddedCases = monthYears.map(() => 0)

        for (const caseIssue of this.casesInCurrentDateRange) {
          const createMonthYear                 = moment(caseIssue.createdAt).format("MMM YY")
          const indexOfCreatedMonthInCategories = monthYears.indexOf(createMonthYear)

          if (caseIssue.hasAReport) {
            speakUpCases[indexOfCreatedMonthInCategories]++
          } else {
            manuallyAddedCases[indexOfCreatedMonthInCategories]++
          }
        }

        if (this.isSelectPresentInViewPolicies("Issue view")) {
          const totalCasesGraphData = {
            vColDataCy      : "4001",
            vCardDataCy     : "4002",
            vCardRef        : "total_cases_card",
            vCardTitleDataCy: "4003",
            vCardTitleRef   : "total_cases_chart_title",
            chartsDataCy    : "4004",
            cardTitle       : "1400",
            options         : getBarChartOptions(monthYears),
            height          : 500,
            series          : [{
              name: this.$t("1403"),
              data: manuallyAddedCases
            }]
          }

          if (this.isSelectPresentInViewPolicies("Report view")) {
            totalCasesGraphData.series.push({
              name: this.$t("1404"),
              data: speakUpCases
            })
          }
          result.push(totalCasesGraphData)
        }
      }
      return result
    },
    totalCasesPerDomainGraph() {
      const result     = []
      const monthYears = this.monthYears()

      if (monthYears && this.pDomains.length) {
        const casesPerDomains = []
        for (const domain of this.pDomains) {
          casesPerDomains.push({
            name : domain.name,
            count: monthYears.map(() => 0)
          })
        }

        for (const caseIssue of this.casesInCurrentDateRange) {
          const createMonthYear                 = moment(caseIssue.createdAt).format("MMM YY")
          const indexOfCreatedMonthInCategories = monthYears.indexOf(createMonthYear)

          const domain = this.pDomains.find(domain => domain.id === caseIssue.domainId)
          casesPerDomains.find(casePerDomain =>
            casePerDomain.name === domain.name
          ).count[indexOfCreatedMonthInCategories]++
        }

        if (this.isSelectPresentInViewPolicies("Issue view") && this.isSelectPresentInViewPolicies("Domain view")) {
          result.push({
            vColDataCy      : "4005",
            vCardDataCy     : "4006",
            vCardRef        : "total_cases_per_domain_card",
            vCardTitleDataCy: "4007",
            vCardTitleRef   : "total_cases_per_domain_chart_title",
            chartsDataCy    : "4008",
            cardTitle       : "1401",
            options         : getBarChartOptions(monthYears, this.pDomains.length),
            height          : 500,
            series          : casesPerDomains.map(casesPerDomain => ({
              name: casesPerDomain.name,
              data: casesPerDomain.count
            }))
          })
        }
      }
      return result
    },
    totalReportsPerChannelGraph() {
      const result     = []
      const monthYears = this.monthYears()

      if (monthYears && this.pChannels.length) {
        const speakUpCases = monthYears.map(() => 0)

        const reportsPerChannels = []
        for (const channel of this.pChannels) {
          reportsPerChannels.push({
            name : channel.name,
            count: 0
          })
        }

        for (const caseIssue of this.casesInCurrentDateRange) {
          const createMonthYear                 = moment(caseIssue.createdAt).format("MMM YY")
          const indexOfCreatedMonthInCategories = monthYears.indexOf(createMonthYear)

          if (caseIssue.hasAReport) {
            speakUpCases[indexOfCreatedMonthInCategories]++

            const channel = this.pChannels.find(channel => channel.id === caseIssue.channelId)
            reportsPerChannels.find(reportPerChannel =>
              reportPerChannel.name === channel.name
            ).count++
          }
        }

        reportsPerChannels.sort((first, second) => second.count - first.count)

        if (this.isSelectPresentInViewPolicies("Report view") && this.isSelectPresentInViewPolicies("Channel view")) {
          result.push({
            vColDataCy      : "4009",
            vCardDataCy     : "4010",
            vCardRef        : "total_reports_per_channel_card",
            vCardTitleDataCy: "4011",
            vCardTitleRef   : "total_reports_per_channel_chart_title",
            chartsDataCy    : "4012",
            cardTitle       : "1402",
            options         : getBarChartOptions(reportsPerChannels.map(
              reportsPerChannel => reportsPerChannel.name
            ), reportsPerChannels.length, true),
            height: getHorizontalBarChartHeight(this.pChannels.length),
            series: [{
              data: reportsPerChannels.map(
                reportsPerChannel => reportsPerChannel.count
              )
            }]
          })
        }
      }
      return result
    },
    totalReportsPerLanguageGraph() {
      const result     = []
      const monthYears = this.monthYears()

      if (monthYears && this.pLanguages.length) {
        const speakUpCases = monthYears.map(() => 0)

        const reportsPerLanguages = []
        for (const language of this.pLanguages) {
          reportsPerLanguages.push({
            name : language.name,
            count: 0
          })
        }

        for (const caseIssue of this.casesInCurrentDateRange) {
          const createMonthYear                 = moment(caseIssue.createdAt).format("MMM YY")
          const indexOfCreatedMonthInCategories = monthYears.indexOf(createMonthYear)

          if (caseIssue.hasAReport) {
            speakUpCases[indexOfCreatedMonthInCategories]++

            const language = this.pLanguages.find(language => language.id === caseIssue.languageId)
            reportsPerLanguages.find(reportPerLanguage =>
              reportPerLanguage.name === language.name
            ).count++

          }

        }
        reportsPerLanguages.sort((first, second) => second.count - first.count)

        if (this.isSelectPresentInViewPolicies("Report view") && this.isSelectPresentInViewPolicies("Language view")) {
          result.push({
            vColDataCy      : "4013",
            vCardDataCy     : "4014",
            vCardRef        : "total_reports_per_language_card",
            vCardTitleDataCy: "4015",
            vCardTitleRef   : "total_reports_per_language_chart_title",
            chartsDataCy    : "4016",
            cardTitle       : "1405",
            options         : getBarChartOptions(reportsPerLanguages.map(
              reportsPerLanguage => reportsPerLanguage.name
            ), reportsPerLanguages.length, true),
            height: getHorizontalBarChartHeight(this.pLanguages.length),
            series: [{
              data: reportsPerLanguages.map(
                reportsPerLanguage => reportsPerLanguage.count
              )
            }]
          })
        }
      }
      return result
    },
    chartData() {
      return [
        ...this.totalNumberOfCasesGraph,
        ...this.totalCasesPerDomainGraph,
        ...this.totalReportsPerChannelGraph,
        ...this.totalReportsPerLanguageGraph
      ]
    }
  },
  methods: {
    handleDateInput() {
      if (this.selectedDates.length === 2) {
        const fromDate = new Date(this.selectedDates[0])
        const toDate   = new Date(this.selectedDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedDates = [this.selectedDates[1], this.selectedDates[0]]
        }
        this.showDateRangeMenu = false
      }
    },
    filterKpisByDateRange(dateRange) {
      if (dateRange.length !== 2) {
        return []
      }

      const [start, end] = dateRange.map(date => new Date(date))
      start.setHours(0, 0, 0, 0)
      end.setHours(23, 59, 59, 999)

      return this.pKpis.filter(kpi => {
        const createdAt = new Date(kpi.createdAt)
        return createdAt >= start && createdAt <= end
      })
    },
    getColor(value, showGreenForPositive) {
      if (value === this.$t("1143")) {
        return "grey--text text--darken-4"
      } else if ((Number(value) >= 0 && showGreenForPositive)
        || (Number(value) < 0 && !showGreenForPositive)) {
        return "secondary--text"
      } else {
        return "error--text text--darken-2"
      }
    },
    getDifference(currentData, previousData, previousCount) {
      const numberOfCases   = previousCount ?? previousData
      const isNotApplicable = numberOfCases === 0
        || previousData === this.$t("1143")
        || currentData === this.$t("1143")
      if (isNotApplicable) {
        return this.$t("1143")
      }

      const difference = currentData - previousData
      return difference > 0 ? this.$t("24", { difference }) : difference
    },
    getAverageTimeToClose(cases) {
      const casesWithClosedAt = cases.filter(kpi => kpi.closedAt)
      if (casesWithClosedAt.length === 0) {
        return this.$t("1143")
      }
      const days = casesWithClosedAt
        .map(kpi => {
          const closedAt              = moment(kpi.closedAt)
          const receivedAtOrCreatedAt = moment(kpi.receivedAt ? kpi.receivedAt : kpi.createdAt)
          const diffInMilliseconds    = closedAt.diff(receivedAtOrCreatedAt)
          return diffInMilliseconds / (1000 * 60 * 60 * 24)
        })

      const totalDays = days.reduce((total, day) => total + day, 0)
      return Math.ceil(totalDays / casesWithClosedAt.length)
    },
    getReports(cases) {
      return cases.filter(kpi => kpi.hasAReport)
    },
    getNumberOfCheckBackReports(reports) {
      return reports.filter(kpi => kpi.hasReporterCheckBack).length
    },
    getCheckBackRate(numberOfReports, numberOfCheckBackReports) {
      return numberOfReports ? Math.ceil(numberOfCheckBackReports / numberOfReports * 100) : this.$t("1143")
    },
    checkNotApplicable(value, locale, localeVariable) {
      return value === this.$t("1143")
        ? this.$t("1143")
        : this.$t(locale, { [localeVariable]: value })
    },
    isSelectPresentInViewPolicies(policyName) {
      const viewPolicies = this.pLoggedInUserPolicies[policyName]
      return viewPolicies && viewPolicies.some(eachViewPolicy =>
        eachViewPolicy.select && eachViewPolicy.select.length > 0)
    },
    checkPropertyInIssueViewPoliciesSelect(property) {
      const issueViewPolicies = this.pLoggedInUserPolicies["Issue view"]
      return issueViewPolicies && issueViewPolicies.some(issueViewPolicy =>
        issueViewPolicy.select && issueViewPolicy.select.includes(property)
      )
    },
    monthYears() {
      if (this.selectedDates.length === 2) {
        const monthYears = []
        const startDate  = moment(this.selectedDates[0])
        const endDate    = moment(this.selectedDates[1])
        while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
          monthYears.push(startDate.format("MMM YY"))
          startDate.add(1, "month")
        }
        return monthYears
      }
    }
  }
}